<template>
  <div class="card-info">
    <div class="card-title">绑定银行卡作为收取佣金账户</div>
    <van-field @click="chooseImg"
               maxLength="23"
               v-model="card"
               @blur="bankInputBlur"
               label="银行卡号"
               @click-right-icon.stop="rightChooseImg"
               input-align="right"
               right-icon="photograph"
               placeholder="请填写您的银行卡号" />
    <van-uploader :preview-image="false"
                  ref="bankImgup"
                  style="display:none;"
                  v-model="fileList"
                  :after-read="afterRead" />

    <van-field v-model="bankname"
               label="所属银行"
               input-align="right"
               placeholder="根据银行卡号自动识别"
                />

    <div class="footer">
      <van-button type="info" color="#3F7C53"
                  @click="commitAction">提交认证</van-button>
    </div>
  </div>
</template>

<script>
  import { Field, Button, Icon, Uploader } from 'vant';
  import { sendBankCardOcr, submitCertification } from "../../utils/api";
  import common from "../../utils/common";
  import {appAction, uwStatisticAction} from "../../utils/util";
  import { AUTH_SUBMIT } from "../../utils/constantNum";
  export default {
    name:'cardInfo',
    components: {
      [Field.name]: Field,
      [Button.name]: Button,
      [Icon.name]: Icon,
      [Uploader.name]: Uploader,
    },
    data () {
      return {
        isOCR: false,
        name: null,
        card: '',
        bankname: null,
        fileList: [],
        bankImg: null,
        fileNo: null,
        isNeedAtd: true,
      }
    },
    watch: {
      card (newValue, oldValue) {
        let val = newValue.replace(/[' ']/g,'')
        if(val.length > 19){
          this.card = this.card.substr(0, 19)
        }
        // if (newValue.length > oldValue.length) {
        //   this.card = newValue.replace(/\s/g, '').replace(/(\d{4})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,})/, '$1 $2 $3 $4 $5')
        // } else {
        //   this.card = this.card.trim()
        // }
      },
    },
    mounted () {
      if (this.$route.query.jumpFrom != undefined ){
        this.$store.dispatch('keepAliveState/addKeepAliveAction', 'cardInfo')
      }
      this.name = this.$route.query.name
      uwStatisticAction('/cardInfo','银行卡信息')
    },
    methods: {
      nameFormatter (val) {
        return val.replace(/[^\u4e00-\u9fa5.]/g, '')
      },
      bankNoFormatter (val) {
        return val.replace(/[^\u4e00-\u9fa5.]/g, '')
      },
      bankInputBlur () {
        if (!this.isNeedAtd) {
          return;
        }
        if (!this.card) {
          return
        }
        if (!common.bankCardVerify(this.card.replace(/\s/g, ''))) {
          this.$toast('请输入正确的银行卡号')
          return
        }
      },
      chooseImg () {
        if (this.isOCR) {
          return
        }
        this.$refs.bankImgup.chooseFile()
      },
      rightChooseImg () {
        this.$refs.bankImgup.chooseFile()
      },
      afterRead (file) {
        console.log(file)
        // 大于15M
        if(file.file.size > 15 * 1024 * 1024){
          Toast('图片太大，请上传小于15M的图片')
          return
        }
        if(!common.imageVerify(file.file.type)){
          this.fileList.splice(detail.index, 1)
          Toast('上传图片必须为png或者jpg格式')
          return
        }
        // 小于100K不进行压缩
        if(file.file.size < 100 * 1024){
          this.saveFile(file.content)
          return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file.file)
        let _this = this
        reader.onload = function (e) {
          let content= file.content //图片的src，base64格式
          let img = new Image()
          img.src = content
          img.onload = function() {
            common.compress(img, (dataURL)=>{
              _this.saveFile(dataURL)
              // console.log(dataURL)
            })
          }
        }
      },
      saveFile(dataURL) {
        let params = {
          fileContent: dataURL
        }
        sendBankCardOcr(params).then(res => {
          this.isOCR = true
          if (res.code === 200) {
            // let data = JSON.parse(common.decrypt(res.data, res.timestamp));
            let data
            if (res.encryptedStatus === '1') {
              data = JSON.parse(common.decrypt(res.data, res.timestamp));
            } else {
              data = res.data

            }
            if (data.businessCode === 200) {
              if (data.bankName) {
                this.isNeedAtd = false
                this.bankname = data.bankName
              }
              this.card = data.bankNumber.replace(/(^\s+)|(\s+$)/g, "")
              this.fileNo = data.fileNo
            }
            this.fileNo = data.fileNo ? data.fileNo : ''
          }
        }).catch(() => {

        });
      },
      commitAction () {
        if (!this.isOCR) {
          this.$toast('请点击相机上传银行卡图片')
          return;
        }
        // if (!this.fileNo) {
        //   this.$toast('图片识别失败，请重新上传')
        //   return
        // }
        if (!this.card) {
          this.$toast('请输入银行卡号')
          return;
        }
        if (!this.bankname) {
          this.$toast('请输入所属银行')
          return;
        }
        let p = {
          uniqueNo: common.getItem('uniqueNo'),
          name: this.name,
          bankCardNo: this.card.replace(/\s/g, ''),
          issueBank: this.bankname,
          picFileNo: this.fileNo
        }
        submitCertification(p).then(res => {
          if (res.code === 200) {
            let data
            if (res.encryptedStatus === '1') {
              data = JSON.parse(common.decrypt(res.data, res.timestamp));
            } else {
              data = res.data
            }
            if (data.businessCode === 200) {
              this.$router.push({ path: '/stationagent/waitSign' })
              if (common.getItem('jumpFrom') != undefined ) {
                appAction(AUTH_SUBMIT)
              }
            }
          }
        })
      }
    },
    beforeRouteLeave (to, from, next) {
      if ( to.name == 'waitSign'){
      } else {
        this.$store.dispatch('keepAliveState/removeKeepAlive', 'cardInfo')
      }
      next()
    }
  }
</script>

<style lang="less" scoped>
  .card-info {
    background: #f5f5f5;
    height: 100%;
    font-size: 0.3rem;
    box-sizing: border-box;
    .card-title {
      padding: 0.3rem 0.3rem 0.2rem;
    }
    .card-upload {
      margin: 0 0.3rem;
      height: 1.5rem;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 0 0.2rem;
      overflow: hidden;
      .van-uploader {
        // border: 0.01rem solid #ccc;
        width: 1.2rem;
        height: 1.2rem;
        box-shadow: 0 -0.01rem 0 0 #ccc, -0.01rem 0 0 0 #ccc, 0.01rem 0 0 0 #ccc,
        0 0.01rem 0 0 #ccc;
      }
    }
    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .van-button {
        width: 100%;
      }
    }
  }
</style>
